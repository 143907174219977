import { z } from 'zod';

export const CreateSingleTaskRequestZodSchema = z.object({
  title: z.string().min(1, 'Title is required'),
  description: z.string().min(1, 'Description is required'),
  publicId: z.string().optional(),
  dueAt: z.string().min(1),
  attachmentUrls: z.array(z.string()).optional(),
  isPrivate: z.boolean().optional(),
  shouldSendEmail: z.boolean().optional(),
  assigneeIds: z.array(z.string()).optional(),
  reviewerIds: z.array(z.string()).optional(),
  taskRelations: z.array(z.any()).optional(),
  ownerId: z.string().optional(),
  areAllReviewersRequired: z.boolean().optional()
});
